<template>
  <div class="app-body">
    <div class="wrapper w-100 m-3">
      <div class="animated fadeIn">
        <b-row>
          <b-col cols="12">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <i class="fa fa-th-large"></i><strong> Mes exploitations</strong>
                <info-message>
                  Nous vous présentons toutes les exploitations que vous avez créees et celles dans lesquelles vous avez
                  été ajoutées.
                </info-message>
                <div class="card-header-actions">
                  <b-button variant="primary" @click="newExploitationModal = true" id="v-step-0">
                    <i class="fa fa-plus"></i>
                    Créer une exploitation
                  </b-button>
                  
                  <b-modal title="Nouvelle exploitation" class="modal-primary" v-model="newExploitationModal"
                           @hide="onModalClose">
                    <b-form>
                      <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                      
                      <c-input type="image" v-model="picture" default="DEFAULT_EXPLOITATION_PICTURE"></c-input>
                      
                      <c-input container-class="mb-3 mt-3" label="Nom de l'exploitation" placeholder="Ex: Mon champ"
                               v-model="name" :state="nameState">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="textarea" label="Commentaire"
                               placeholder="Entrez un commentaire..." v-model="comment">
                      </c-input>
                    </b-form>
                    
                    <div slot="modal-footer" class="w-100 text-center">
                      <button-spinner variant="success" type="submit" class="px-4" @click="createExploitation"
                                      :fetching="fetchingCreateExploitation">
                        Valider
                      </button-spinner>
                      <b-button variant="secondary" @click="newExploitationModal = false" class="ml-2">
                        Annuler
                      </b-button>
                    </div>
                  </b-modal>
                </div>
              </div>
              <div class="text-center" v-if="fetchingExploitationList">
                <b-spinner></b-spinner>
                <br>
                Chargement...
              </div>
              <b-tabs v-else-if="exploitations.length" content-class="mt-3 border-0" v-model="listExploitationOpen">
                <b-tab v-for="(tab, index) in tabs">
                  <template slot="title">
                    <div :id="'v-tab-'+index">
                      <i :class="tab.icon"></i> {{tab.title}} ({{tab.exploitations.length}})
                    </div>
                  </template>
                  <b-row class="exploitations">
                    <b-col md="6" lg="4" xl="3" v-for="exploitation in tab.exploitations">
                      <b-card :img-src="asset(exploitation.picture)" no-body>
                        <div slot="header">
                          {{exploitation.name}}
                          <div class="card-header-actions">
                            <b-link href="#" class="card-header-action btn-setting">
                              <i class="icon-bell"></i>
                              <b-badge pill variant="danger">0</b-badge>
                              <b-badge pill variant="success">0</b-badge>
                            </b-link>
                          </div>
                        </div>
                        <b-card-body>
                          {{exploitation.comment}}
                          <br>
                          <b-button variant="success" class="mt-2" :to="exploitation.url">
                            <i class="icon-size-fullscreen"></i>
                            Ouvrir
                          </b-button>
                        </b-card-body>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
              <p class="text-center" v-else>Vous ne travaillez dans aucune exploitation.</p>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {Api, Toast, asset, env, functionGeneratorJSON, Storage, Auth, endGuide} from "../../helpers";
  
  export default {
    name: "Home",
    title: "PIA - Accueil",
    data() {
      return {
        exploitations: [],
        newExploitationModal: false,
        picture: null,
        name: '',
        comment: '',
        submitted: false,
        error: null,
        fetchingCreateExploitation: false,
        fetchingExploitationList: false,
        isFirstLogin: '',
        lastExploitationOpen: '',
        guideExploitations: {},
        listExploitationOpen:0
      }
    },
    async created() {
      
      Storage.store('guideExploitations', {})
      this.fetchingExploitationList = true
      let exploitationsList;
      const res = await Api.get('/exploitation/list')
      if (res.data.status === 'success' && res.data.data) {
        exploitationsList = res.data.data
        this.exploitations = res.data.data.map(item => ({...item, url: '/exploitation/' + item.id}))
      }
      else {
        this.error = res.data.error
      }
      this.fetchingExploitationList = false
      
      // open the exploitation tab which contains at least one item assuming that the initial value is 0
      
      if(exploitationsList.filter(item => !item.isOwn).length)
        this.listExploitationOpen = 1
      
      
      let stepsList
      
      /*Fetch the list of steps in the guides*/
      
      const res1 = await Api.get('/assistant/steps/list')
      if (res1.data.status === 'success' && res1.data.data) {
        stepsList = res1.data.data
        Storage.store('stepsList', res1.data.data)
      }
      else {
        this.error = res.data.error
      }
      /*Fetch the guide of all the exploitations available do it after the nextTick to avoid non-availability of the data*/
      await this.$nextTick(() => {
        if (exploitationsList.length != 0) {
          console.log('on fetch la liste des assistants')
          this.fetchAssistants(exploitationsList).then((values) => {
            // all the values are available
            console.log('values ', values)
            const guideExploitations = {}
            values.map(val => {
              let stepsFilled = val.steps
              if (stepsFilled === undefined || stepsFilled.length == 0) {
                stepsFilled = []
                stepsList.map(step => {
                  stepsFilled.push({
                    ...step,
                    status: 'IN_PROGRESS'
                  })
                })
              }
              
              guideExploitations[val.id] = {
                idExploitation: val.id,
                status: val.status,
                guideSteps: stepsFilled
              }
            })
            this.guideExploitations = guideExploitations
            Storage.store('guideExploitations', guideExploitations)
          })
        }
      })
      console.log('hey')
  
  
      /*fetch the initial parameters for the guide*/
      let isFirstLogin = this.isFirstLogin = Auth.user().isFirstLogin
      let lastExploitationOpen = this.lastExploitationOpen = Auth.user().lastExploitationOpen
      console.log(Auth.user().lastExploitationOpen)
  
      // the setTimeOut is to wait for the Storage.set guideExploitations to perform
      
      setTimeout(()=>{
  
        if (isFirstLogin)
          Toast.guide_msg('Bonjour bienvenue dans le PIA. Pour pouvoir poursuivre votre activité, vous devez créer une exploitation',
            null,
            this.openExploitationModal,
            null,
            functionGeneratorJSON("Créer une exploitation"),
            0
          )
        else {
          if(lastExploitationOpen == null){
            if (this.exploitations.length == 0)
              Toast.guide_msg("Vous n'avez pas encore créé une exploitation, veuillez le faire pour poursuivre dans le guide",
                null,
                this.openExploitationModal,
                functionGeneratorJSON("Créer une exploitation"),
                functionGeneratorJSON("Créer une exploitation"),
                0,
                null,null,null,null,null,
                endGuide
              )
            else
              Toast.guide_msg("Ouvrer une exploitation pour continuer le guide",
                this.openExploitationModal,
                null,
                functionGeneratorJSON("Créer une autre exploitation"),
                null,
                0,
                null,null,null,null,null,
                endGuide)
          }else {
            console.log('last Exploitation Open ', lastExploitationOpen)
            Toast.guide_msg("Continuez où vous vous êtes arretés la dernière fois ?", null, null, null, null, 0,
              [lastExploitationOpen],
              this.currentStep(lastExploitationOpen),
              null, null,
              this.furtherRedirect,
              endGuide
            )
          }
        }
      },1100)
      
      
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
      isFirstLogin(newVal, oldVal) {
      
      },
      lastExploitationOpen() {
      
      }
    },
    mounted() {
    
    },
    computed: {
      tabs() {
        return [
          {
            icon: 'cui-user',
            title: 'Exploitations créées',
            exploitations: this.exploitations.filter(item => item.isOwn)
          },
          {
            icon: 'cui-user-follow',
            title: 'Exploitations rejointes',
            exploitations: this.exploitations.filter(item => !item.isOwn)
          },
        ]
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_EXPLOITATION_PICTURE'))
      },
      valid() {
        return this.name.trim().length >= 3
      },
      openExploitationModal() {
        this.newExploitationModal = true
      },
      onModalClose() {
        this.picture = null
        this.name = ''
        this.comment = ''
        this.submitted = false
        this.error = null
        this.fetchingCreateExploitation = false
      },
      openExploitation(id) {
        this.$router.push('/exploitation/' + id)
      },
      createExploitation() {
        this.submitted = true
        if (!this.valid()) return
        
        this.fetchingCreateExploitation = true
        Api.post('/exploitation/create', {
          name: this.name,
          comment: this.comment,
          picture: this.picture
        })
          .then(res => {
              
              if (res.data.status === 'success' && res.data.data) {
                this.error = null
                this.newExploitationModal = false
                this.exploitations.push({
                  ...res.data.data,
                  url: '/exploitation/' + res.data.data.id
                })
                console.info(res.data.data)
                // Toast.success('Exploitation créée avec succès !')
                Toast.guide_msg("Exploitation créée avec succès !", this.openExploitationModal,
                  this.openExploitation,
                  functionGeneratorJSON("Créer une autre exploitation"),
                  functionGeneratorJSON("Ouvrir l'exploitation", res.data.data.id),
                  0,
                  null,
                  false,
                  null,
                  null,
                  null,
                  endGuide
                )
                
                
                let stepsFilled = []
                
                let stepsList = Storage.get('stepsList')
                stepsList.map(step => {
                  stepsFilled.push({
                    ...step,
                    status: 'IN_PROGRESS'
                  })
                })
                
                let guideExploitations = Storage.get('guideExploitations')
                
                guideExploitations[res.data.data.id] = {
                  idExploitation: res.data.data.id,
                  status: 'IN_PROGRESS',
                  guideSteps: stepsFilled
                }
                Storage.store('guideExploitations', guideExploitations)
                
              }
              else {
                this.error = res.data.error
              }
            }
          )
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateExploitation = false
          })
      },
      furtherRedirect() {
        const us = this
        return {
          0: {
            redirect: function () {
              us.$router.push('/home/')
            }
          },
          1: {
            redirect: function (id) {
              us.$router.push('/exploitation/' + id)
            }
          },
          2: {
            redirect: function (id) {
              us.$router.push('/exploitation/' + id + '/management/warehouses/create')
            }
          },
          3: {
            redirect: function (id, idWarehouse) {
              console.log('id et idWarehouse ', id, idWarehouse)
              us.$router.push('/exploitation/' + id + '/management/warehouses/' + idWarehouse)
            }
          },
          4: {
            redirect: function (id) {
              us.$router.push('/exploitation/' + id + '/staff/employees')
            }
          },
        }
      },
      currentStep(id) {
        
        console.log('guide est ', this.guideExploitations)
        
        const guideExploitationsFor = this.guideExploitations[id]
        console.log('guide exploitations For ', guideExploitationsFor)
        const step = guideExploitationsFor.guideSteps.find(item => item.isCurrent)
        
        
        // 4 because the id of warehouse Supply
        if (step.id != 4) {
          return {
            ...step,
            params: [id]
          }
        }
        return {
          ...step,
          params: [id, step.warehouseOpen]
        }
        // don't forget to consider the index of steps.
        
      },
      async fetchAssistants(exploitationsList) {
        let results = [];
        
        for (let exploit of exploitationsList) {
          const res = await Api.get('/assistant/', {
            exploitationId: exploit.id,
          })
          if (res.data.status === 'success' && res.data.data) {
            console.log('guideSteps for the exploitaion ', exploit.id)
            results.push({id: exploit.id, steps: res.data.data.steps, status: res.data.data.status})
          }
          else {
            this.error = res.data.error
          }
        }
        
        return results
      },
    }
  }
</script>

<style scoped>
  #unit-select{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .card-img{
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .exploitations .card-body{
    height: 100px;
    text-overflow: ellipsis;
  }
</style>
